export default class Commissions {
  public systemCommissionPercent: number;
  public systemCommissionFixed: number;
  public applicationCommissionPercent: number;
  public applicationCommissionFixed: number;
  public terminalCommissionsOnOther: number;
  public application: number;
  public system: number;

  constructor(commissions: any) {
    this.systemCommissionPercent = commissions.system_commission_percent;
    this.systemCommissionFixed = commissions.system_commission_fixed;
    this.applicationCommissionPercent = commissions.application_commission_percent;
    this.applicationCommissionFixed = commissions.application_commission_fixed;
    this.terminalCommissionsOnOther = commissions.terminal_commissions_on_other;
    this.application = commissions.application;
    this.system = commissions.system;
  }
}
