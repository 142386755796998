import Commissions from '@/models/commissions';

export default class Payment {
    public applicationKey: string;
    public hash: string;
    public orderId: string;
    public acquirer: string;
    public acquirerId: number;
    public terminalId: number;
    public currency: string;
    public description: string;
    public error_description: string;
    public bets_description: string;
    public is_bet_payment: boolean;
    public cardPan: string;
    public cardName: string;
    public email: string;
    public phone: string;
    public amount: number;
    public totalAmount: number;
    public commissionType: number;
    public totalCommission: number;
    public commissions: Commissions | null;
    public status: string;
    public autoClearing: boolean;
    public language: string;
    public callbacks: string;
    public createdAt: string;
    public updatedAt: string;
    public deletedAt: string;

    constructor(payment: any) {
        this.applicationKey = payment.application_key;
        this.hash = payment.hash;
        this.orderId = payment.order_id;
        this.acquirer = payment.acquirer;
        this.acquirerId = payment.acquirer_id;
        this.terminalId = payment.terminal_id;
        this.currency = payment.currency;
        this.description = payment.description;
        this.error_description = payment.error_description;
        this.bets_description = payment.bets_description;
        this.is_bet_payment = payment.is_bet_payment;
        this.cardPan = payment.card_pan;
        this.cardName = payment.card_name;
        this.email = payment.email;
        this.phone = payment.phone;
        this.amount = payment.amount;
        this.commissionType = payment.commission_type;
        this.totalAmount = payment.total_amount;
        this.totalCommission = payment.total_commission;
        this.commissions = payment.commissions === null ? null : new Commissions(payment.commissions);
        this.status = payment.status;
        this.autoClearing = payment.auto_clearing;
        this.language = payment.language;
        this.callbacks = payment.callbacks;
        this.createdAt = payment.created_at;
        this.updatedAt = payment.updated_at;
        this.deletedAt = payment.deleted_at;
    }
}
