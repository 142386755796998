export default class Application {
    public key: null|string = null;
    public title: null|string = null;
    public hostname: null|string = 'https://api.example.com/platform';
    public postURL: null|string = 'https://api.example.com/platform/callbacks/post';
    public backURL: null|string = 'https://api.example.com/platform/callbacks/back';
    public successURL: null|string = 'https://api.example.com/platform/callbacks/success';
    public failedURL: null|string = 'https://api.example.com/platform/callbacks/fail';
    public allowedNetworks: null|string = '*';
    public publicKey: null|string = null;
    public privateKey: null|string = null;
    public percent: null|number = 0;
    public fixed: null|number = 0;
    public testing: boolean = true;
    public deleted: boolean = false;

    constructor(app: any) {
        this.key = app.key ?? null;
        this.title = app.title ?? null;
        this.hostname = app.hostname ?? 'https://api.example.com/platform';
        this.postURL = app.post_url ?? 'https://api.example.com/platform/callbacks/post';
        this.backURL = app.back_url ?? 'https://api.example.com/platform/callbacks/back';
        this.successURL = app.success_url ?? 'https://api.example.com/platform/callbacks/success';
        this.failedURL = app.failed_url ?? 'https://api.example.com/platform/callbacks/fail';
        this.allowedNetworks = app.allowed_networks !== undefined ? JSON.parse(app.allowed_networks).join(',') : '*';
        this.publicKey = app.public_key ?? null;
        this.privateKey = app.private_key ?? null;
        this.percent = app.percent ?? 0;
        this.fixed = app.fixed ?? 0;
        this.testing = app.testing ?? true;
        this.deleted = app.deleted_at !== undefined ? app.deleted_at !== null : false;
    }
}
