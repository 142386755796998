export type OrganizationDocument = Record<'title' | 'path' | 'size', string>

export default class Organization {
    public id: null|number = null;
    public title: null|string = null;
    public address: null|string = null;
    public bin: null|string = null;
    public iik: null|string = null;
    public bik: null|string = null;
    public documents: OrganizationDocument[] = [];
    public moderatedAt: null|string = null;
}
